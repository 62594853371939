import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faCog, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Button,
  Col,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import zxcvbn from "zxcvbn";
import { CustomEndData } from "../../commons/models/models";
import { secretOptions } from "../../constants/newSecret";
import { Context } from "../../context/auth/Context";
import { types } from "../../context/types";
import { fetchNewSecret, fetchFileUploadURL, fetchUploadFileToS3 } from "../../services/services";
import { isJsonString } from "../../utils";
import { createBodyRequest } from "../../utils/secret";
import { CustomErrorField } from "../components/CustomErrorField";
import { CustomModalLabel } from "../components/CustomModalLabel";
import { InputCredential } from "../components/InputCredential";
import { InputJson } from "../components/InputJson";
import { InputMessage } from "../components/InputMessage";
import { InputFile } from "../components/InputFile";
import { InputPassword } from "../components/InputPassword";
import { InputQr } from "../components/InputQr";
import { SecretSettings } from "../components/SecretSettings";
import ReactBSAlert from "react-bootstrap-sweetalert";

export const NewSecretForm = ({
  initialValues,
  setPrevValuesAux,
  config,
  setConfig,
  qty2Aux,
  addNewLabel,
  labelOptions,
  modalClassic,
  multipleSelect,
  setmultipleSelect,
  openedCollapseSecret,
  setOpenedCollapseSecret,
  openedCollapseSettings,
  setOpenedCollapseSettings,
  setOpenedCollapseTemplate,
}: any) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading file...");
  const [singleSelect, setsingleSelect] = useState([]);
  const [font, setFont] = useState({
    color: "-1",
    bgColor: "",
    text: "",
    description: "",
  });

  const [sharepassBtnTxt, setSharepassBtnTxt] = useState("SHAREPASS IT!");

  const [qrData, setQrData] = useState("No QR code scanned yet");
  const [fileData, setFileData] = useState(new File([],""));

  const [fileErrors, setFileErrors] = useState("");

  // Get name from context
  const { user, dispatch } = useContext(Context);

  const readFile = async function () {
    let result = await new Promise((resolve) => {

        const reader = new FileReader()

        reader.onabort = (err) => {
          setIsLoading(false);
          console.log('file reading was aborted', err);
        }

        reader.onerror = (err) => {
          setIsLoading(false);
          console.log('file reading has failed', err);
        }

        reader.onload = () => {
          // Do whatever you want with the file contents
          console.log("Data set");
          resolve(reader.result);
        }

        reader.readAsDataURL(fileData);
    });
    console.log("RESULT", result);
    return result;
}

  return (<>
    {isLoading && (
      
      <ReactBSAlert
          style={{ display: "block", marginTop: "-100px" }}
          showCancel={false}
          showConfirm={false} title={""} onConfirm={function (response?: any) {
            throw new Error("Function not implemented.");
          } }        >
        <p className="display-3">{loadingText}</p>
      </ReactBSAlert>
      
    )}
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={(values) => {
        setPrevValuesAux(values);
        console.log("validate",values);
        let errors = {};
        const error = "This field is required";
        const errorDescription =
          "description cannot be longer than 99 characters";

        if (values.private && values.private.length > 99) {
          errors.private = `Private ${errorDescription}`;
        }

        if (values.public && values.public.length > 99) {
          errors.public = `Public ${errorDescription}`;
        }

        if (
          !user.purposeMode &&
          singleSelect &&
          singleSelect.label === "Password" &&
          !values.password
        ) {
          errors.password = error;
          setFont((prevFont) => ({
            ...prevFont,
            noData: true,
          }));
        } else if (
          !user.purposeMode &&
          singleSelect &&
          singleSelect.label === "Password" &&
          values.password
        ) {
          let checked = zxcvbn(values.password);

          if (checked.score > -1 && checked.score <= 2) {
            setFont((prevFont) => ({
              ...prevFont,
              bgColor: "bg-danger",
              color: "danger-txt",
              text: "Weak",
            }));
          } else if (checked.score === 3) {
            setFont((prevFont) => ({
              ...prevFont,
              bgColor: "bg-warning",
              color: "warning-txt",
              text: "Ok",
            }));
          } else if (checked.score >= 4) {
            setFont((prevFont) => ({
              ...prevFont,
              bgColor: "bg-success",
              color: "success-txt",
              text: "Strong",
            }));
          }

          setFont((prevFont) => ({
            ...prevFont,
            description: `${checked.crack_times_display.online_no_throttling_10_per_second} to crack`,
            noData: false,
          }));
        } else if (
          !user.purposeMode &&
          singleSelect &&
          singleSelect.label === "Message" &&
          !values.message
        ) {
          errors.message = error;
        } else if (!user.purposeMode && singleSelect && singleSelect.label === "Credentials") {
          if (!values.credentialEmail) errors.credentialEmail = error;
          /*else if (
            values.credentialEmail &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
              values.credentialEmail
            )
          ) {
            errors.credentialEmail = "Invalid email address";
          }*/

          if (!values.credentialPassword) errors.credentialPassword = error;
        } else if (!user.purposeMode && singleSelect && singleSelect.label === "JSON") {
          if (!values.json) errors.json = error;
          else if (!isJsonString(values.json)) {
            errors.json = "Please, inter a valid JSON object";
          }
        } else if (!user.purposeMode && singleSelect && singleSelect.label === "File" && !fileData.name) {
          setFileErrors(error);
          errors.file = error;
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        // We need to validate start & end date again
        if (
          config.availability &&
          config.availability.dateTimeStart &&
          config.availability.dateTimeEnd &&
          config.availability.dateTimeEnd <= config.availability.dateTimeStart
        ) {
          return;
        }

        setSharepassBtnTxt("PLEASE WAIT...");

        let endData: CustomEndData = {
          private: values.private,
          public: values.public,
          type: singleSelect.value,
          data: null,
        };

        switch (singleSelect.label) {
          case "Password":
            endData.data = values.password;
            break;
          case "Message":
            endData.data = values.message;
            break;
          case "Credentials":
            endData.data = {
              email: values.credentialEmail,
              password: values.credentialPassword,
              hostname: values.credentialHostname,
              f2a: values.credential2Fa,
            };
            break;
          case "JSON":
            endData.data = values.json;
            break;
          case "QR":
            endData.data = qrData;
            break;
          case "File":

            if(!user.purposeMode){
              setIsLoading(true);
              endData.data = await readFile();

              //File is empty
              if(endData.data == 'data:'){
                history.push("/admin/secret/error", {
                  data: "Your file is empty. Please, try again with a different one.",
                });
    
                return;
              }

              setLoadingText("Encrypting file content...");
            }
            
            break;
        }

        const { body, randomWord }: any = await createBodyRequest(
          endData,
          config
        );

        /**
         * If type file lets create pre-signed URL and upload the file
         */
        if(!user.purposeMode && body.type == 'file'){
          
          let bodyFileUploadURL = {
            contentLength: body.secret.length,
            filename: fileData.name
          };

          setLoadingText("Uploading file...");

          const { status, details, message, redirect } = await fetchFileUploadURL(bodyFileUploadURL);

          if(status != "Success"){
            console.log("status", status);
            history.push("/admin/secret/error", {
              data: message,
            });

            return;
          }

          details["fields"]["x-amz-meta-userid"]    = localStorage.getItem("userId");
          details["fields"]["x-amz-meta-filename"]  = fileData.name;

          const response = await fetchUploadFileToS3(details, body.secret);

          //File was uploaded correctly, update secret value with file key
          if(response.status == 204){
            body.secret = details.fields.key;
          } else {

            console.log("status: ", response.status);
            history.push("/admin/secret/error", {
              data: response.message,
            });
  
            return;
          }

          setIsLoading(false);
        }

        const { status, details, message, redirect } = await fetchNewSecret(
          body
        );

        setSharepassBtnTxt("SHAREPASS IT!");

        if (redirect) {
          dispatch({
            type: types.logout,
          });
          history.push("/auth/login");
        } else if (status === "Success") {
          setSubmitting(false);
          details.randomWord = randomWord;
          details.body = body;
          history.push("/admin/secret/success?ts=" + Date.now(), {
            data: details,
          });
        } else {
          history.push("/admin/secret/error", {
            data: message,
          });
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form className="form-formik">

          <div
            aria-multiselectable={true}
            className="card-collapse mb-3"
            id="accordionSecret"
            role="tablist"
          >
            <Card className="card-plain">
              <CardHeader role="tab">
                <a
                  aria-expanded={openedCollapseSecret}
                  href="#pabo"
                  data-parent="#accordionSecret"
                  data-toggle="collapse"
                  onClick={(e) => {
                    e.preventDefault();
                    if(!openedCollapseSecret){
                      setOpenedCollapseSecret(!openedCollapseSecret);
                      setOpenedCollapseSettings(false);
                      setOpenedCollapseTemplate(false);
                    }
                    
                  }}
                >
                  Secret data
                  <i className="tim-icons icon-minimal-down" />
                </a>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={openedCollapseSecret}
                className="collapse-settings"
              >
                <CardBody>
                  <Card className="card-plain card-subcategories">
                    <CardBody>
                      <Row>
                        <Col md={6}>
                          <Label>Secret type</Label>
                          <FormGroup>
                            <Select
                              className="react-select info"
                              classNamePrefix="react-select"
                              name="select"
                              value={singleSelect}
                              onChange={(value) => setsingleSelect(value)}
                              options={secretOptions}
                              placeholder="Choose secret type"
                            />
                          </FormGroup>
                          {singleSelect && singleSelect.label === "Password" && !user.purposeMode && (
                            <InputPassword font={font} />
                          )}
                          {singleSelect && singleSelect.label === "Message" && !user.purposeMode && (
                            <InputMessage />
                          )}
                          {singleSelect && singleSelect.label === "Credentials" && !user.purposeMode && (
                            <InputCredential />
                          )}
                          {singleSelect && singleSelect.label === "JSON" && !user.purposeMode && <InputJson />}
                          {singleSelect && singleSelect.label === "QR" && !user.purposeMode && (
                            <InputQr setQrData={setQrData} qrData={qrData} />
                          )}
                          {singleSelect && singleSelect.label === "File" && !user.purposeMode && (
                            <InputFile  
                              setFileData={setFileData} 
                              errors={fileErrors} 
                              setErrors={setFileErrors} 
                            />
                          )}
                        </Col>
                        <Col md={6}>
                          <div className="box-label">
                            {labelOptions.length <= 1 ? (
                              <Label>
                                You don't have a label yet.{" "}
                                <b className={"cursor-p"} onClick={addNewLabel}>
                                  Create one
                                </b>
                              </Label>
                            ) : (
                              <div>
                                <Label className="">Label</Label>
                                <FontAwesomeIcon
                                  icon={faCog}
                                  className="left5 cursor-p"
                                  onClick={addNewLabel}
                                />
                              </div>
                            )}
                          </div>
                          <CustomModalLabel isOpen={modalClassic} toggle={addNewLabel} />
                          <Select
                            className={`mb-2 react-select ${
                              multipleSelect[0] ? multipleSelect[0].color : "mb-2 info"
                            }`}
                            classNamePrefix="react-select"
                            placeholder="Choose label"
                            name="multipleSelect"
                            closeMenuOnSelect
                            isMulti
                            value={multipleSelect}
                            onChange={(value) => setmultipleSelect(value)}
                            options={labelOptions}
                            isDisabled={labelOptions.length <= 1}
                          />

                          <Label>
                            Private description
                            <FontAwesomeIcon
                              id="pDescription"
                              icon={faInfoCircle}
                              color="#0d6efd"
                              width="40px"
                            />
                            <UncontrolledTooltip
                              delay={0}
                              target="pDescription"
                              placement="right"
                            >
                              This description is private for your own use to be able to
                              identify secrets easily.
                            </UncontrolledTooltip>
                          </Label>
                          <FormGroup>
                            <Field type="text" name="private" />
                          </FormGroup>
                          <ErrorMessage name="private" component={CustomErrorField} />
                          <Label>Public description</Label>
                          <FontAwesomeIcon
                            id="publicDescription"
                            icon={faInfoCircle}
                            color="#0d6efd"
                            width="40px"
                          />
                          <UncontrolledTooltip
                            delay={0}
                            target="publicDescription"
                            placement="right"
                          >
                            This description will be shown to those users that access the
                            link, describe here the purpose or the content of the secret
                            being shared.
                          </UncontrolledTooltip>
                          <FormGroup>
                            <Field type="text" name="public" />
                          </FormGroup>
                          <ErrorMessage name="public" component={CustomErrorField} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardBody>
              </Collapse>
            </Card>
          </div>
          <div
            aria-multiselectable={true}
            className="card-collapse"
            id="accordion"
            role="tablist"
          >
            <Card className="card-plain">
              <CardHeader role="tab">
                <a
                  aria-expanded={openedCollapseSettings}
                  href="#pabo"
                  data-parent="#accordion"
                  data-toggle="collapse"
                  onClick={(e) => {
                    e.preventDefault();
                    if(!openedCollapseSettings){
                      setOpenedCollapseSettings(!openedCollapseSettings); 
                      setOpenedCollapseTemplate(false);
                      setOpenedCollapseSecret(false);
                    }
                    
                  }}
                >
                  Security settings
                  <i className="tim-icons icon-minimal-down" />
                </a>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={openedCollapseSettings}
                className="collapse-settings"
              >
                <CardBody>
                  <Card className="card-plain card-subcategories">
                    <CardBody>
                      <SecretSettings
                        setConfig={setConfig}
                        config={config}
                        qty2Aux={qty2Aux}
                      />
                    </CardBody>
                  </Card>
                </CardBody>
              </Collapse>
            </Card>
          </div>
          <hr />
          <Row className="center-sp">
            <Button
              className="btn-fill"
              color="primary"
              type="submit"
              disabled={
                isSubmitting ||
                singleSelect.length === 0 ||
                (singleSelect && singleSelect.value === "") ||
                (singleSelect &&
                  singleSelect.label === "QR" &&
                  qrData === "No QR code scanned yet" && !user.purposeMode) ||
                config.error
              }
            >
              <FontAwesomeIcon
                icon={faPaperPlane}
                color="#fff"
                className="right5"
              />
              {sharepassBtnTxt}
            </Button>
          </Row>
        </Form>
      )}
    </Formik>
    </>
  );
};
