import { faEdit, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faCode,
  faCopy,
  faExternalLinkSquareAlt, faPaintBrush, faSignOut, faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import useAPIError from "../../../../commons/hooks/useAPIError";
import { Context } from "../../../../context/auth/Context";
import { DataContext } from "../../../../context/data/DataContext";
import { dataTypes, types } from "../../../../context/types";
import {
  fetchDeleteSecurityKey,
  fetchDeleteWebhook, fetchGlobalSignOut, fetchPutWebhook,
  fetchUpdateName,
  fetchUserProfile
} from "../../../../services/services";
import { DarkLightMode } from "../../../../views/components/DarkLightMode";
import { DefaultInputs } from "../../../../views/components/DefaultInputs";
import { InputUpdateProfile } from "../../../../views/components/InputUpdateProfile";
import { InputWebhookSettings } from "../../../../views/components/InputWebhookSettings";
import { NavItemSetting } from "../../../../views/components/NavItemSetting";
import { EnvelopeSettings } from "../../../../views/components/EnvelopeSettings";

interface ErrorForm {
  verifyCode?: string;
  friendlyDevice?: string;
}

const Profile = () => {
  const history = useHistory();

  // Notify
  const { addNotify } = useAPIError();

  const {
    user: { userDetails: userData },
    dispatch,
  } = useContext(Context);

  // Context for profile
  const {
    profile, //: { sso, publicKeysCred },
    dispatch2,
  } = useContext(DataContext);

  console.log(localStorage.getItem("lastPath"));

  const currentURL = new URLSearchParams(history.location.search);

  if (currentURL.get("tab")) {
    profile.profileTabName = currentURL.get("tab");
  }

  const [pageTabs, setpageTabs] = useState(profile.profileTabName || "user");

  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  const expiresIn = localStorage.getItem("expiresIn");
  const userId = localStorage.getItem("userId");

  function isValidHttpUrl(string: string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "https:";
  }

  useEffect(() => {
    const updateUserDetails = async () => {
      if (typeof profile.sso === "undefined") {
        const { status, details, redirect } = await fetchUserProfile();
        //const { status, details, redirect } = await fetchUserDetails();
        if (redirect) {
          dispatch({
            type: types.logout,
          });
          history.push("/auth/login");
        } else if (status === "Success" && details) {
          /*setUserDetails(details);
          setIsSwitched(
            details.webhook && details.webhook.active
              ? details.webhook.active
              : false
          );
          setHasSSO(details.sso === true);
          setHasWebhook(details.webhook && details.webhook.url ? true : false);
          setIsCredKeys(details.publicKeysCred);*/

          dispatch2({
            type: dataTypes.profile,
            payload: { ...details, profileTabName: pageTabs },
          });
        }
      }
    };

    updateUserDetails();
  }, []);

  const [isSwitched, setIsSwitched] = useState(
    profile.webhook && profile.webhook.active ? profile.webhook.active : false
  );

  const [credKeys, setIsCredKeys] = useState(profile.publicKeysCred);

  const [hasSSO, setHasSSO] = useState(profile.sso === true);

  const [userDetails, setUserDetails] = useState(profile);
  const [hasWebhook, setHasWebhook] = useState(
    profile.webhook && profile.webhook.url ? true : false
  );
  const [switchMFA, setSwitchMFA] = useState(false);
  const [friendlyDeviceName, setFriendlyDeviceName] = useState("");

  const [showStep, setShowStep] = useState("password");
  const [has2FA, sethas2FA] = useState(false);
  const [TOPTURL, setTOPTURL] = useState("");
  const [TOPTSecret, setTOPTSecret] = useState("");

  const [action, setAction] = useState("update");

  const [isSigningOutGlobally, setIsSigningOutGlobally] = useState(false);

  const changeActiveTab = (e: any, tabState: string, tabName: string) => {
    e.preventDefault();
    switch (tabState) {
      case "pageTabs":
        setpageTabs(tabName);
        break;
      default:
        break;
    }
  };

  /*
   * Show alert before delete a credential
   */
  const [alert, setAlert] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const warningWithConfirmMessage = (id: string, name: string) => {
    setShowAlert(true);
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          setAuxId(id);
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="success"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        Are you sure you want to delete the key <strong>"{name}"</strong>?<br />
      </ReactBSAlert>
    );
  };

  /*
   * Hide alert when delete a secret
   */
  const hideAlert = () => {
    setShowAlert(false);
    setAlert({});
  };

  /*
   * Delete a secret
   */
  const [auxId, setAuxId] = useState("");
  useEffect(() => {
    // Delete from the database
    const deleteSecurityKey = async () => {
      const body = { credId: auxId };

      const { status, redirect } = await fetchDeleteSecurityKey(body);

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        let tempCreds = Object.assign({}, credKeys);

        delete tempCreds[auxId];

        let tempDetails: any = {};
        tempDetails["publicKeysCred"] = tempCreds;

        setIsCredKeys(tempCreds);

        setAuxId("");

        /*setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Deleted!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="success"
            btnSize=""
          >
            Your security key has been deleted.
          </ReactBSAlert>
        );*/
        addNotify("Your security key was deleted successfully", "success");

        dispatch2({
          type: dataTypes.profile,
          payload: { ...userDetails, ...tempDetails, profileTabName: pageTabs },
        });
      }
    };

    if (auxId !== "") {
      deleteSecurityKey();
    }
  }, [auxId]);

  const handleGlobalSignOut = async () => {
    try{
      setIsSigningOutGlobally(true);

      const { status, redirect } = await fetchGlobalSignOut();

      setIsSigningOutGlobally(false);

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        localStorage.clear();
        
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else {
        addNotify(
          "There was an error while closing all you sessions, please try again.",
          "danger"
        );
      }
      
    }catch(e){
      setIsSigningOutGlobally(false);
      addNotify(
        "There was an error while closing all you sessions, please try again.",
        "danger"
      );
    }
  };

  const copyRefreshToken = () => {
    navigator.clipboard.writeText(localStorage.getItem("refreshToken"));
    addNotify(
      "Refresh token copied",
      "success"
    );
  }

  return (
    <>
      {showAlert && alert}
      <div className="content">
        <Card className="card-stats">
          <CardBody>
            <Nav
              className="nav-pills-primary nav-pills-icons justify-content-center mb-2"
              pills
            >
              <NavItemSetting
                icon={faUserCircle}
                pageTabs={pageTabs}
                changeActiveTab={changeActiveTab}
                tabName="user"
                title="Account"
              />

              
              <NavItemSetting
                icon={faCode}
                pageTabs={pageTabs}
                changeActiveTab={changeActiveTab}
                tabName="webhooks"
                title="Dev"
              />
              <NavItemSetting
                icon={faPaintBrush}
                pageTabs={pageTabs}
                changeActiveTab={changeActiveTab}
                tabName="settings"
                title="Theme"
              />
              <NavItemSetting
                icon={faEnvelope}
                pageTabs={pageTabs}
                changeActiveTab={changeActiveTab}
                tabName="envelope"
                title="Envelope"
              />
              <NavItemSetting
                icon={faEdit}
                pageTabs={pageTabs}
                changeActiveTab={changeActiveTab}
                tabName="default"
                title="Default"
              />
            </Nav>
            
            <TabContent
              className="tab-space tab-subcategories"
              activeTab={pageTabs}
            >
              <TabPane tabId="user">
                <Row>
                  <Col lg="12" md="12">
                    <Card className="custom-card">
                      <CardBody>
                        <CardTitle tag="h3">Profile info</CardTitle>
                        <Row>
                          <Col lg="4" md="4" sm="6" xs="12">
                            <Label>Email</Label>
                            <FormGroup>
                              <Input
                                type="text"
                                value={userData.email}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" md="12">
                    <Card className="custom-card">
                      <CardBody>
                        <CardTitle tag="h3">Full name</CardTitle>
                        <div>
                          <p>Update your name</p>
                        </div>
                        <Row>
                          <Col lg="4" md="4" sm="6" xs="12">
                            <Formik
                              initialValues={{
                                firstname: userData.name ? userData.name : "",
                                lastname: userData.lastname
                                  ? userData.lastname
                                  : "",
                              }}
                              validate={(values) => {
                                let errors: {
                                  firstname?: string;
                                  lastname?: string;
                                } = {};
                                const error = "This field is required";

                                if (!values.firstname) {
                                  errors.firstname = error;
                                }

                                if (!values.lastname) {
                                  errors.lastname = error;
                                }

                                return errors;
                              }}
                              onSubmit={async (values, { setSubmitting }) => {
                                let endData: {
                                  name: string;
                                  lastname: string;
                                } = {
                                  name: values.firstname,
                                  lastname: values.lastname,
                                };

                                const { status, redirect } =
                                  await fetchUpdateName(endData);

                                setSubmitting(false);

                                if (redirect) {
                                  dispatch({
                                    type: types.logout,
                                  });
                                  history.push("/auth/login");
                                } else if (status === "Success") {
                                  addNotify(
                                    "Details updated successfully",
                                    "success"
                                  );

                                  dispatch({
                                    type: types.userDetails,
                                    payload: {
                                      name: values.firstname,
                                      lastName: values.lastname,
                                      email: userData.email,
                                      userDetails: {
                                        name: values.firstname,
                                        lastname: values.lastname,
                                        email: userData.email,
                                      },
                                    },
                                  });

                                  dispatch2({
                                    type: dataTypes.profile,
                                    payload: {
                                      ...userDetails,
                                      profileTabName: pageTabs,
                                    },
                                  });
                                } else {
                                  addNotify(
                                    "There was an error while updating the details",
                                    "danger"
                                  );
                                }
                              }}
                            >
                              {({ isSubmitting }) => (
                                <Form className="form-formik">
                                  <InputUpdateProfile />
                                  <Row>
                                    <Col>
                                      <Button
                                        className="btn-fill btn-sm"
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting}
                                      >
                                        {!isSubmitting
                                          ? "Ok"
                                          : "PLEASE WAIT..."}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>
                              )}
                            </Formik>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12" md="12">
                    <Card className="custom-card">
                      <CardBody>
                        <CardTitle tag="h3">Global sign out</CardTitle>
                        
                        <Row>
                          <Col lg="6" md="6" sm="12" xs="12">
                          <div>
                          <p>Sign this account out of all SharePass sessions across all devices. It can take up to 15 minutes for the process to complete.
This account will be able to immediately sign back in.</p>
                          </div>
                          <Button
                                        className="btn-fill btn-sm"
                                        color="secondary"
                                        type="submit"
                                        disabled={isSigningOutGlobally}
                                        onClick={handleGlobalSignOut}
                                      >
                                        <FontAwesomeIcon
                                          className="cursor-p"
                                          icon={faSignOut}
                                        />{" "}Global sign out
                                      </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="webhooks">
                {userDetails &&
                  userDetails.subscription &&
                  userDetails.subscription.plan &&
                  userDetails.subscription.plan &&
                  userDetails.subscription.plan.limits &&
                  userDetails.subscription.plan.limits.webhooks && (
                    <>
                      <Row>
                        <Col lg="12" md="12">
                          <Card className="custom-card">
                            <CardBody>
                              <CardTitle tag="h3">Webhooks</CardTitle>
                              <div>
                                <p>
                                  Receive notifications when a secret is
                                  successfully retrieved.
                                </p>
                              </div>
                              <Row>
                                <Col lg="4" md="4" sm="6" xs="12">
                                  <Formik
                                    initialValues={{
                                      url:
                                        userDetails.webhook &&
                                        userDetails.webhook.url
                                          ? userDetails.webhook.url
                                          : "",
                                      password:
                                        userDetails.webhook &&
                                        userDetails.webhook.secretToken
                                          ? userDetails.webhook.secretToken
                                          : "",
                                    }}
                                    validate={(values) => {
                                      let errors = {};
                                      const error = "This field is required";

                                      if (!values.url) {
                                        errors.url = error;
                                      }

                                      if (!isValidHttpUrl(values.url)) {
                                        errors.url = "Invalid URL";
                                      }

                                      return errors;
                                    }}
                                    onSubmit={async (
                                      values,
                                      { setSubmitting, resetForm }
                                    ) => {
                                      let body: {
                                        active: boolean;
                                        url: string;
                                        secretToken?: string;
                                      } = {
                                        active: isSwitched,
                                        url: values.url,
                                      };

                                      if (values.password) {
                                        body.secretToken = values.password;
                                      }

                                      let res;

                                      if (action === "update") {
                                        const response = await fetchPutWebhook(
                                          body
                                        );

                                        if (response.redirect) {
                                          dispatch({
                                            type: types.logout,
                                          });
                                          history.push("/auth/login");
                                        } else {
                                          res = response;

                                          let tempWebhookDetails = {
                                            webhook: body,
                                          };

                                          dispatch2({
                                            type: dataTypes.profile,
                                            payload: {
                                              ...userDetails,
                                              ...tempWebhookDetails,
                                              profileTabName: pageTabs,
                                            },
                                          });
                                        }
                                      } else if (action === "delete") {
                                        const response =
                                          await fetchDeleteWebhook();

                                        if (response.redirect) {
                                          dispatch({
                                            type: types.logout,
                                          });
                                          history.push("/auth/login");
                                        } else {
                                          res = response;

                                          let tempWebhookDetails = profile;
                                          delete tempWebhookDetails.webhook;

                                          dispatch2({
                                            type: dataTypes.profile,
                                            payload: {
                                              ...userDetails,
                                              ...tempWebhookDetails,
                                              profileTabName: pageTabs,
                                            },
                                          });
                                        }
                                      }

                                      if (res.status === "Success") {
                                        if (action === "delete") {
                                          setIsSwitched(false);
                                          setHasWebhook(false);
                                          resetForm();
                                        } else {
                                          setHasWebhook(true);
                                        }
                                        addNotify(
                                          "Webhook settings updated successfully",
                                          "success"
                                        );
                                      } else {
                                        let message =
                                          "There was an error while updating the details";

                                        if (res.message) {
                                          message = res.message;
                                        }

                                        addNotify(message, "danger");
                                      }

                                      setAction("update");

                                      setSubmitting(false);
                                    }}
                                  >
                                    {({ isSubmitting, submitForm }) => (
                                      <Form className="form-formik">
                                        <InputWebhookSettings
                                          authVars={{
                                            token,
                                            refreshToken,
                                            expiresIn,
                                            userId: userId,
                                          }}
                                          isSwitched={isSwitched}
                                          setIsSwitched={setIsSwitched}
                                        />
                                        <Row>
                                          <Col>
                                            <Button
                                              className="btn-fill btn-sm"
                                              color="primary"
                                              type="submit"
                                              disabled={isSubmitting}
                                            >
                                              {!isSubmitting
                                                ? "Ok"
                                                : "PLEASE WAIT..."}
                                            </Button>

                                            {hasWebhook && (
                                              <button
                                                onClick={() => {
                                                  setAction("delete");
                                                  submitForm();
                                                }}
                                                disabled={isSubmitting}
                                                type="button"
                                                className="btn-fill btn-sm btn btn-danger btn-danger-fix ml-2"
                                              >
                                                Delete
                                              </button>
                                            )}
                                          </Col>
                                        </Row>
                                      </Form>
                                    )}
                                  </Formik>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12" md="12">
                          <Card className="custom-card">
                            <CardBody>
                              <CardTitle tag="h3">Refresh token</CardTitle>
                              <div>
                                <p>You can use the refresh token to generate new access tokens. Access tokens are needed to authenticate API calls to the backend of SharePass.</p>
                                <p>A sign-out invalidates the current refresh token, whereas a global sign-out invalidates all refresh tokens. See the <a href="https://developers.sharepass.com/" target="_blank">API documentation</a> for further details.</p>
                              </div>
                              <Row>
                                <Col lg="4" md="4" sm="6" xs="12">
                                <Button
                                        className="btn-fill btn-sm"
                                        color="secondary"
                                        type="button"
                                        onClick={copyRefreshToken}
                                      >
                                        <FontAwesomeIcon
                                          className="cursor-p"
                                          icon={faCopy}
                                        />{"  "}&nbsp; Copy refresh token
                                      </Button>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </>
                  )}
                {!userDetails && (
                  <Row>
                    <Col lg="12" md="12">
                      <Card className="custom-card">
                        <CardBody>
                          <CardTitle tag="h3">Webhooks</CardTitle>

                          <Row></Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                {userDetails && !userDetails.subscription && (
                  <Row>
                    <Col lg="12" md="12">
                      <Card className="custom-card">
                        <CardBody>
                          <CardTitle tag="h3">Webhooks</CardTitle>

                          <Row>
                            <Col lg="12" md="12" className="text-center">
                              <p>
                                Only available for plans that allow webhooks.
                              </p>
                            </Col>

                            <Col lg="12" md="12" className="text-center">
                              <button
                                onClick={() => {
                                  history.push("/admin/settings/plans");
                                }}
                                className="btn btn-sm btn-primary"
                              >
                                Check our plans
                              </button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                {userDetails &&
                  userDetails.subscription &&
                  userDetails.subscription.plan &&
                  userDetails.subscription.plan &&
                  userDetails.subscription.plan.limits &&
                  !userDetails.subscription.plan.limits.webhooks && (
                    <Row>
                      <Col lg="12" md="12">
                        <Card className="custom-card">
                          <CardBody>
                            <CardTitle tag="h3">Webhooks</CardTitle>
                            <Row>
                              <Col lg="12" md="12" className="text-center">
                                <p>
                                  Your current subscription doesn't allow
                                  webhooks, consider upgrading your plan to get
                                  more features.
                                </p>
                              </Col>

                              <Col lg="12" md="12" className="text-center">
                                <button
                                  onClick={() => {
                                    history.push("/admin/settings/plans");
                                  }}
                                  className="btn btn-sm btn-primary"
                                >
                                  Check our plans
                                </button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
              </TabPane>
              <TabPane tabId="settings">
                <Row>
                  <Col lg="12" md="12">
                    <Card className="custom-card profile">
                      <CardBody>
                        <CardTitle tag="h3">Theme settings</CardTitle>
                        <Row>
                          <Col lg="12" md="12">
                            <p>
                              Switch your preferred mode between light or dark
                              mode
                            </p>
                            <DarkLightMode tabName={pageTabs} />
                            <br />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="envelope">
                <EnvelopeSettings
                  tabName={pageTabs}
                  dispatchProfile={dispatch2}
                  profile={profile}
                />
              </TabPane>
              <TabPane tabId="default">
                <DefaultInputs
                  tabName={pageTabs}
                  dispatchProfile={dispatch2}
                  profile={profile}
                />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default Profile;
