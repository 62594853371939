import React, { useState } from "react";
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { NewLabelForm } from "./NewLabelForm";
import { RemoveLabelForm } from "./RemoveLabelForm";


interface labelsModalParams {
  isOpen: boolean;
  admin?: boolean;
  toggle: any;
}
export const CustomModalLabel = ({ isOpen, admin, toggle }:labelsModalParams) => {
  const [horizontalTabs, sethorizontalTabs] = useState("add");

  const changeActiveTab = (e, tabName) => {
    e.preventDefault();
    sethorizontalTabs(tabName);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className="modal-header justify-content-center">
        <button
          aria-hidden={true}
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          <i className="tim-icons icon-simple-remove" />
        </button>
        <Nav className="nav-pills-info" pills>
          <NavItem>
            <NavLink
              data-toggle="tab"
              href="#"
              className={horizontalTabs === "add" ? "active" : ""}
              onClick={(e) => changeActiveTab(e, "add")}
            >
              <h6 className="title title-up">Add</h6>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              data-toggle="tab"
              href="#"
              className={horizontalTabs === "remove" ? "active" : ""}
              onClick={(e) => changeActiveTab(e, "remove")}
            >
              <h6 className="title title-up">Remove</h6>
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div className="new-label">
        <ModalBody className="text-center ">
          <Row>
            <Card>
              <CardBody>
                <TabContent className="tab-space" activeTab={horizontalTabs}>
                  <TabPane tabId="add">
                    <NewLabelForm toggle={toggle} admin={admin} />
                  </TabPane>
                  <TabPane tabId="remove">
                    <RemoveLabelForm toggle={toggle} admin={admin} />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Row>
        </ModalBody>
      </div>
    </Modal>
  );
};
