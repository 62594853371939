/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
// javascript plugin used to create scrollbars on windows
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PerfectScrollbar from "perfect-scrollbar";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
// reactstrap components
import { Button, Collapse, Nav } from "reactstrap";
import FrontendConfig from "../../assets/js/config";
import { Context } from "../../context/auth/Context";
import { DataContext } from "../../context/data/DataContext";
import { types } from "../../context/types";
import { fetchRevokeToken } from "../../services/services";

var ps;

const Sidebar = (props) => {
  const { user, dispatch } = useContext(Context);
  /*const { profile } = useContext(DataContext);*/

  const [state, setState] = React.useState({});
  const sidebarRef = React.useRef(null);
  const location = useLocation();

  const [isWebApp, setIsWebApp] = React.useState(true);

  /*React.useEffect(() => {
    setState(getCollapseStates(props.routes));
    setIsWebApp(
      window.location.hostname.length <= 25
    );
  }, []);*/
  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    const { rtlActive, superAdmin } = props;
    
    return routes.map((prop, key) => {
      if (
        prop.redirect || 
        superAdmin != prop.superAdmin || 
        typeof user == "undefined" ||
        ((prop.purpose === 0 || prop.purpose === 1) && user.purposeMode != prop.purpose)
      ) {
        return null;
      }
      
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];

        return (
          <li
            className={`${getCollapseInitialState(prop.views) ? "active" : ""}`}
            key={key}
          >
            <a
              href="#"
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, ...st });
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {rtlActive ? prop.rtlName : prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">
                    {rtlActive ? prop.rtlMini : prop.mini}
                  </span>
                  <span className="sidebar-normal">
                    {rtlActive ? prop.rtlName : prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={state[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink
            to={prop.layout + prop.path}
            activeClassName=""
            onClick={props.closeSidebar}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{rtlActive ? prop.rtlName : prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
                <span className="sidebar-normal">
                  {rtlActive ? prop.rtlName : prop.name}
                </span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  const goToDashboard = (e) => {
    e.preventDefault();
    props.history.push("/admin/dashboard");
  };

  // Logout
  const handleLogout = async () => {
    await fetchRevokeToken();

    // Check and remove light mode if is applied
    if (document.body.classList.contains("white-content"))
      document.body.classList.toggle("white-content");

    dispatch({
      type: types.logout,
    });

    // Clear local storage
    localStorage.removeItem("idToken");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("userId");

    props.history.push("/auth/login");
    /*const redirect_logout = `${window.location.protocol}//${window.location.host}/auth/login`;
    window.location.href =
      FrontendConfig.auth_url +
      "/logout?client_id=" +
      FrontendConfig.sso.cid +
      "&logout_uri=" +
      encodeURIComponent(redirect_logout);*/
  };

  const handleProfile = () => {
    props.history.push("/admin/settings/profile");
  };

  const handleNewSecret = () => {
    props.history.push("/admin/secret/new-secret");
  };

  const handleNewEnvelope = () => {
    props.history.push("/admin/envelope/add");
  };

  const handleBilling = () => {
    props.history.push("/admin/settings/plans");
  };

  const { activeColor, logo } = props;
  let logoImg = null;
  let logoText = null;
  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
        <a
          href={logo.outterLink}
          className="simple-text logo-mini"
          onClick={(e) => {
            props.closeSidebar;
            goToDashboard(e);
          }}
        >
          <div className="logo-img">
          {activeColor == "admin"? logo.imgSrc : <img src={logo.imgSrc} alt="react-logo" />}
          </div>
        </a>
      );
      logoText = (
        <a
          href={logo.outterLink}
          className="simple-text logo-normal"
          onClick={(e) => {
            props.closeSidebar;
            goToDashboard(e);
          }}
        >
          {activeColor == "admin"? "Go back" : logo.text}
        </a>
      );
    } else {
      logoImg = (
        <NavLink
          to={logo.innerLink}
          className="simple-text logo-mini"
          onClick={props.closeSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </NavLink>
      );
      logoText = (
        <NavLink
          to={logo.innerLink}
          className="simple-text logo-normal"
          onClick={props.closeSidebar}
        >
          {logo.text}
        </NavLink>
      );
    }
  }
  return (
    <div className="sidebar" data={activeColor}>
      <div className="sidebar-wrapper" ref={sidebarRef}>
        {logoImg !== null || logoText !== null ? (
          <div className="logo">
            {logoImg}
            {logoText}
          </div>
        ) : null}
        {!props.superAdmin && typeof user != "undefined" && user.purposeMode ? (
          <div className="text-center mt-4">
            <Button
              className="btn-simple btn btn-round btn-sharepass"
              onClick={() => {
                props.closeSidebar();
                handleNewEnvelope();
              }}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="#fff"
              />{" "}
              New envelope
            </Button>

            <Button
              className="btn-icon btn-simple btn btn-round btn-sharepass-mini"
              onClick={() => {
                props.closeSidebar();
                handleNewSecret();
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} color="#fff" />
            </Button>
          </div>
        ) : (!props.superAdmin && 
          <div className="text-center mt-4">
            <Button
              className="btn-simple btn btn-round btn-sharepass"
              onClick={() => {
                props.closeSidebar();
                handleNewSecret();
              }}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="#fff"
              />{" "}
              New secret
            </Button>

            <Button
              className="btn-icon btn-simple btn btn-round btn-sharepass-mini"
              onClick={() => {
                props.closeSidebar();
                handleNewSecret();
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} color="#fff" />
            </Button>
          </div>
        )}
        
        <Nav>{createLinks(props.routes)}</Nav>
        <Nav className="btn-logout">
          <div className="custom-hr"></div>
          {isWebApp ? (
            <>
              {false && !props.superAdmin && (<li
                className={activeRoute("/admin/settings/profile")}
                key="settings"
              >
                <a
                  href="#"
                  data-toggle="collapse"
                  aria-expanded={false}
                  onClick={() => {
                    props.closeSidebar();
                    handleProfile();
                  }}
                >
                  <i className="fa fa-cogs" />
                  <p>Settings</p>
                </a>
              </li>)}

              {false && (<>
                <li
                  className={activeRoute("/admin/settings/plans")}
                  key="billings"
                >
                  <a
                    href="#"
                    data-toggle="collapse"
                    aria-expanded={false}
                    onClick={() => {
                      props.closeSidebar();
                      handleBilling();
                    }}
                  >
                    <i className="fa fa-credit-card" />
                    <p>Plans & Billings</p>
                  </a>
                </li>
              
              <div className="custom-hr" />
              <li className="" key="logoutsidebar">
                <a
                  href="#logout"
                  data-toggle="collapse"
                  aria-expanded={false}
                  onClick={handleLogout}
                >
                  <>
                    <i className="fa fa-sign-out-alt" />
                    <p>Sign out</p>
                  </>
                </a>
              </li></>)}
            </>
          ) : (
            <li key="settings">
              <a
                href={`${FrontendConfig.current_url}/admin/dashboard`}
                data-toggle="collapse"
                aria-expanded={false}
                target="_blank"
              >
                <i className="fa fa-desktop" />
                <p>Portal</p>
              </a>
            </li>
          )}
        </Nav>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  activeColor: PropTypes.oneOf(["primary", "blue", "green", "orange", "red", "admin"]),
  rtlActive: PropTypes.bool,
  routes: PropTypes.array.isRequired,
  logo: PropTypes.oneOfType([
    PropTypes.shape({
      innerLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.any.isRequired,
      text: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      outterLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.any.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ]),
  // this is used on responsive to close the sidebar on route navigation
  closeSidebar: PropTypes.func,
};

export default Sidebar;
