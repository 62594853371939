import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import {
  faCode,
  faComment,
  faFile,
  faKey,
  faQrcode
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { getColorName } from "./settings";

/* When create a new secret */
export const createBodyRequest = async (data, settings) => {
    
  const user = JSON.parse(localStorage.getItem("user"));
  /* Default values for the body */
  let body = {
    /*
     *Accessibility (required, default value is false
     */
    lockedByOwner: settings.lockedByOwner,

    /*
     * Availability (required)
     * Pay attention for !
     */
    otl: !settings.availability.isSwitched,
    lockAfterUse: settings.availability.isSwitched2,

    /*
     * Type of secret (password, message, credential, json or qr)
     */
    type: data.type,
  };

  /*
   * Label
   */
  if (settings.label.color && settings.label.name) {
    body.label = {
      codeName: settings.label.name,
      color: getColorName(settings.label.color),
    };
  }

  /*
   * Visibility (array of ip addresses)
   */
  if (settings.ips && settings.ips.length > 0) body.ipList = settings.ips;

  /*
   * Authorization (no required)
   */
  const pinAux =
    settings.authorization && settings.authorization.pin
      ? settings.authorization.pin
      : 0;

  let opt;
  if (
    settings.authorization &&
    settings.authorization.opt &&
    settings.authorization.opt === "Static"
  )
    opt = "Static";
  else if (settings.authorization && settings.authorization.opt === "Dynamic")
    opt = "Dynamic";
  else if (
    settings.authorization &&
    settings.authorization.opt === "Security key"
  )
    opt = "Security key";

  if (pinAux) {
    if (opt === "Dynamic")
      body.pin = {
        dynamic: pinAux,
      };
    else if (opt === "Static")
      body.pin = {
        static: pinAux,
      };
  }

  if (
    opt === "Security key" &&
    settings.authorization &&
    settings.authorization.keys &&
    settings.authorization.keys.length > 0
  ) {
    delete body.pin;
    body.keys = [];
 
    for (let i = 0, size = settings.authorization.keys.length; i < size; i++) {
      
      let secContentKey = {
        id: settings.authorization.keys[i]["id"],
        name:  settings.authorization.keys[i]["name"],
        value:  settings.authorization.keys[i]["valueAux"],
        transports:  JSON.parse(settings.authorization.keys[i]["transports"])
      };

      if(settings.authorization.keys[i]["labelAux"] && settings.authorization.keys[i]["labelAux"]["codeName"]){
        secContentKey["label"] = {
          codeName: settings.authorization.keys[i]["labelAux"]["codeName"],
          color: settings.authorization.keys[i]["labelAux"]["color"],
        };
      }
      
      body.keys.push(secContentKey);
    }
  }

  /*
   * Availability (no required)
   */
  let qty = settings.availability.qty;
  if (qty) qty = parseInt(qty);

  let qty2 = settings.availability.qty2;
  if (qty2) qty2 = parseInt(qty2);

  // Available from
  const option = settings.availability.opt;
  const isRelative =
    option === "Minutes" || option === "Hours" || option === "Days";
  const isAbsolute = option === "Specific date";

  if (isRelative) {
    if (option === "Minutes") {
      body.availableFrom = {
        relative: qty * 60,
      };
    } else if (option === "Hours") {
      body.availableFrom = {
        relative: qty * 3600,
      };
    } else if (option === "Days") {
      body.availableFrom = {
        relative: qty * 3600 * 24,
      };
    }
  } else if (isAbsolute) {
    body.availableFrom = {
      absolute: settings.availability.dateTimeStart / 1000,
    };
  }

  /*
   * TTL
   */
  const option2 = settings.availability.opt2;
  const isRelative2 =
    option2 === "Minutes" ||
    option2 === "Hours" ||
    option2 === "Days" ||
    option2 === "Never expire";
  const isAbsolute2 = option2 === "Specific date";

  if (isRelative2) {
    if (option2 === "Minutes") {
      body.ttl = {
        relative: qty2 * 60,
      };
    } else if (option2 === "Hours") {
      body.ttl = {
        relative: qty2 * 3600,
      };
    } else if (option2 === "Days") {
      body.ttl = {
        relative: qty2 * 3600 * 24,
      };
    } else if (option2 === "Never expire") {
      body.ttl = {
        relative: 0,
      };
    }
  } else if (isAbsolute2) {
    body.ttl = {
      absolute: settings.availability.dateTimeEnd / 1000,
    };
  } else if (settings.ttl) {
    body.ttl = settings.ttl;
  }

  /*
   * Private and public description (no required)
   */
  if (data.private) body.privateDescription = data.private;
  if (data.public) body.publicDescription = data.public;

  /*
   * Generate secret
   */
  let secretContent;

  const endData = data.data;
  switch (data.type) {
    case "password":
      secretContent = endData;
      break;
    case "message":
      secretContent = endData;
      break;
    case "credentials":
      secretContent = JSON.stringify({
        user: endData.email,
        password: endData.password,
        hostname: endData.hostname,
        secret: endData.f2a,
      });
      break;
    case "QR":
    case "custom":
    case "file":
      secretContent = endData;
      break;
    default:
      break;
  }

  //if is envelope secret is empty
  if (!secretContent && !user.purposeMode) return;

  if(user.purposeMode){
    body.secret = null;
    return { body };
  }else{
    let randomWord = window.crypto.getRandomValues(new Uint8Array(32));
    randomWord = _arrayBufferToBase64(randomWord).replace(/=/g, "");
  
    randomWord = randomWord.replace(/\//g, "a");
    randomWord = randomWord.replace(/\+/g, "b");
  
    let encryptedData = await encryptSecret(randomWord, secretContent);
  
    body.secret = _arrayBufferToBase64(encryptedData);
  
    return { body, randomWord };
  }
};

export function _arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export async function encryptSecret(key, content) {
  let enc = new TextEncoder();
  let encoded = enc.encode(content);
  let nonce = window.crypto.getRandomValues(new Uint8Array(12));

  var aesKey = base64ToArrayBuffer(key);
  aesKey = await window.crypto.subtle.importKey(
    "raw",
    aesKey,
    "AES-GCM",
    true,
    ["encrypt", "decrypt"]
  );

  let encriptedArrayBuffer = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: nonce,
    },
    aesKey,
    encoded
  );

  var uint8View = new Uint8Array(encriptedArrayBuffer);

  var mergedArray = new Uint8Array(nonce.length + uint8View.length);
  mergedArray.set(nonce);
  mergedArray.set(uint8View, nonce.length);

  return mergedArray;
}

export function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

/* When get all secrets for list view */
export const parseSecret = (secrets) => {
  secrets.forEach((secret) => {
    const { status, customStatus } = getStatus(secret);

    secret.statusAux = status;
    secret.lockedAux = secret.lockedByOwner ? (
      <i className="fa fa-lock text-danger"></i>
    ) : (
      <i className="fa fa-unlock text-success"></i>
    );
    secret.customClass = customStatus;
    secret.dateFromNow = moment(secret.creation * 1000)
      .fromNow()
      .toString();
    secret.dateToShow = moment(secret.creation * 1000)
      .format("ddd D MMM YYYY HH:mm:ss")
      .toString();
  });

  return secrets;
};

/*
 * Check status for each secret
 */
const getStatus = (secret) => {
  let status = "";
  let customStatus = "success";
  if (secret.secretErased) {
    status = "Erased";
    customStatus = "danger";
  } else if (secret.expiry < Date.now() / 1000) {
    status = "Expired";
    customStatus = "danger";
  } else if (secret.availableFrom > Date.now() / 1000) {
    status = "Planned";
    customStatus = "warning";
  } else if (secret.lockedByOwner) {
    status = "Locked";
    customStatus = "warning";
  } else if (secret && secret.pin && secret.pin.attempsRemaining === 0) {
    status = "Temporary Locked / Blocked";
    customStatus = "warning";
  } else {
    status = "Available";
  }

  switch (secret.type) {
    case "password":
      secret.icon = faKey;
      secret.iconName = "Password";
      break;
    case "message":
      secret.icon = faComment;
      secret.iconName = "Message";
      break;
    case "credentials":
      secret.icon = faAddressCard;
      secret.iconName = "Credentials";
      break;
    case "custom":
      secret.icon = faCode;
      secret.iconName = "JSON";
      break;
    case "QR":
      secret.icon = faQrcode;
      secret.iconName = "QR";
      break;
    case "file":
      secret.icon = faFile;
      secret.iconName = "File";
      break;
    default:
      break;
  }

  return { status, customStatus };
};

/*
 * Default values for new secret form
 */
export const defaultValues = {
  password: "",
  message: "",
  credentialEmail: "",
  credentialPassword: "",
  json: "",
  private: "",
  public: "",
};

export const isMobileTablet = function () {
  let check = false;

  if (navigator)
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export  const decrypt = async function(key, nonceCiphertextTag) {			
	const SERVER_ENCRYPTION_IV_LENGTH = 12; // For GCM a nonce length of 12 bytes is recommended!
	var nonce = nonceCiphertextTag.subarray(0, SERVER_ENCRYPTION_IV_LENGTH);
	var ciphertextTag = nonceCiphertextTag.subarray(SERVER_ENCRYPTION_IV_LENGTH);

	var aesKey = base64ToArrayBuffer(key);
	aesKey = await window.crypto.subtle.importKey('raw', aesKey, 'AES-GCM', true, ['encrypt', 'decrypt']);
	var decrypted = await crypto.subtle.decrypt({name: 'AES-GCM', iv: nonce}, aesKey, ciphertextTag);
	return new TextDecoder().decode(decrypted);
}
