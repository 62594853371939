import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { checkIpValue } from "../../utils";

export const InputVisibility = ({ setConfig, config }: any) => {
  const validateIPaddress = (ipaddress: string) =>
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress
    );

  const [selectIp, setSelectedIp] = useState(config.ips ? config.ips : []);
  const addIpAddress = (ipAddress: any) => {
    let indexOf = selectIp.indexOf(ipAddress);
    if (selectIp.length <= 9 && validateIPaddress(ipAddress) && indexOf === -1)
      setSelectedIp((ips) => [...ips, ipAddress]);

    // Clear input value
    setValue("");
  };

  const [value, setValue] = useState("");
  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const removeIp = (ip: any) => {
    let ips = selectIp.filter((ipAux: any) => ipAux !== ip);
    setSelectedIp(ips);
    if (ips.length === 0)
      setConfig((prevConfig: any) => ({ ...prevConfig, ips: [] }));
  };

  useEffect(() => {
    if (selectIp.length > 0)
      setConfig((prevConfig: any) => ({ ...prevConfig, ips: selectIp }));
  }, [selectIp, setConfig]);

  useEffect(() => {
    if (config.ips) {
      setSelectedIp(config.ips);
    }
  }, [config]);

  return (
    <div>
      <Row className="mt-2">
        <Col md="3"></Col>
        <Col md="6">
          <Label>Apply visibility filter by public IP addresses.</Label>
          <br />
          <Label>IP address. (10 max).</Label>
          <FormGroup className="flex">
            <ReactInputMask
              name="ipAddress"
              onChange={onChange}
              value={value}
              formatChars={{
                9: "[0-9.]",
              }}
              className="custon-input-mask"
              mask="999999999999999"
              maskChar={null}
              alwaysShowMask={false}
              beforeMaskedValueChange={(newState, oldState, userInput) => {
                let value = newState.value;
                const oldValue = oldState.value;
                let selection = newState.selection;
                let cursorPosition = selection ? selection.start : null;
                const result = checkIpValue(value);
                if (!result) {
                  value = value.trim();
                  // try to add . before the last char to see if it is valid ip address
                  const newValue =
                    value.substring(0, value.length - 1) +
                    "." +
                    value.substring(value.length - 1);
                  if (checkIpValue(newValue)) {
                    cursorPosition++;
                    selection = { start: cursorPosition, end: cursorPosition };
                    value = newValue;
                  } else {
                    value = oldValue;
                  }
                }

                return {
                  value,
                  selection,
                };
              }}
            />
            <Button
              color="default"
              className="btn-add"
              onClick={(e) => addIpAddress(value)}
            >
              <FontAwesomeIcon icon={faPlus} color="" />
            </Button>
          </FormGroup>
          <Label className="semi-bold">Allowed IPs</Label>
          <div className="box-ips">
            {selectIp.map((ip, index) => (
              <div className="flex-start" key={`ip ${index}`}>
                <p className="ip-address-p">{ip}</p>
                <div className="display-flex">
                  <FontAwesomeIcon
                    className="cursor-p"
                    icon={faTrash}
                    color="#ff4757"
                    onClick={() => {
                      removeIp(ip);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};
