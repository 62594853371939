import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faCog, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Progress,
} from "reactstrap";
import { QRCode } from "react-qrcode-logo";
import ReactCardFlip from 'react-card-flip';
import {
  fetchOpenEnvelope,
  fetchSendEnvelope,
} from "../../services/auth";
import {
  fetchUploadFileToS3
} from "../../services/services";
import {
  b64decode,
  b64encode,
} from "../../services/common";
import zxcvbn from "zxcvbn";
import { Form, Formik } from "formik";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactCodeInput from "react-code-input";
import FrontendConfig from "../../assets/js/config.js";
import { InputCredential } from "../components/InputCredential";
import { InputJson } from "../components/InputJson";
import { InputMessage } from "../components/InputMessage";
import { InputFile } from "../components/InputFile";
import { InputPassword } from "../components/InputPassword";
import { InputQr } from "../components/InputQr";
import { isJsonString } from "../../utils";
import { createEnvelopeBodyRequest } from "../../utils/envelope";

export const EnvelopeOpen = ({id, pin}:any) => {


  const [isLoading, setIsLoading] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");
  const [envelopeType, setEnvelopeType] = useState("");
  const codeRef = useRef(undefined);
  const [font, setFont] = useState({
    color: "-1",
    bgColor: "",
    text: "",
    description: "",
  });

  const [sharepassBtnTxt, setSharepassBtnTxt] = useState("SHAREPASS IT!");

  const [qrData, setQrData] = useState("No QR code scanned yet");
  const [fileData, setFileData] = useState(new File([],""));

  const [fileErrors, setFileErrors] = useState("");

  const [loadingText, setLoadingText] = useState("Loading file...");

  const [envelopeSent, setEnvelopeSent] = useState(false);
  const [emailSender, setEmailSender] = useState('');
  const [publicDescription, setPublicDescription] = useState('');

  const [publicKey, setPublicKey] = useState({});
  const [sendingToken, setSendingToken] = useState({});
  const [uploadDetails, setUploadDetails] = useState({});

  const [progressBar, setProgressBar] = useState(100);

  const [progressBarClass, setProgressBarClass] = useState("progress-primary");
  const [progressBarInterval, setProgressBarInterval] = useState<Number | null>(null);


  useEffect(()=>{
    let myInterval:any;
    if(sendingToken.expiry){

      if(progressBarInterval){
        clearInterval(progressBarInterval);
      }
      myInterval = setInterval(() => {

        let now = Date.now() / 1000;
        let percentage = (( sendingToken.expiry - now ) / sendingToken.duration) * 100;
        
        if (percentage > 0) {
          
          if(percentage < 10){
            setProgressBarClass("progress-danger");
          }else if(percentage < 45){
            setProgressBarClass("progress-warning");
          }else if(percentage < 75){
            setProgressBarClass("progress-success");
          }

          setProgressBar(percentage);
        } else {
          
          if(!envelopeSent && sharepassBtnTxt == "SHAREPASS IT!"){
            clearInterval(myInterval);
            window.location.reload();
          }
          return;
        }
        
      }, 1000);
      
      setProgressBarInterval(myInterval);
    }
  }, [sendingToken, envelopeSent, sharepassBtnTxt]);

  const setDetails = function(details: any){
    if(details.publicDescription){
      setPublicDescription(details.publicDescription);
    }
    setEmailSender(details.email);
    setPublicKey(details.keyPair);
    setEnvelopeType(details.type);
    setSendingToken(details.sendingToken);

    if(details.upload){
      setUploadDetails(details.upload);
    }
  }

  const handleOpen = async (pin = '') => {

    try {

      setIsLoading(true);

      const { status, details, message } = await fetchOpenEnvelope(id, pin);
      
      setIsLoading(false);

      if (status === "Success") {
        //If returns a security key challenge it needs to be resolved
        if(details && details.allowedCredentials){
          let allowedCreds = [];

          for (let i = 0, size = details.allowedCredentials.length; i < size; i++) {
            allowedCreds.push({
              id: b64decode(details.allowedCredentials[i]["id"]),
              type: "public-key",
              transports: details.allowedCredentials[i]["transports"],
            });
          }
          //----------get creds from security key or platform authenticator
          const signinOptions = {
            challenge: b64decode(details.challenge),
            timeout: 1800000,
            rpId: FrontendConfig.rp_id,
            userVerification: "discouraged",
            allowCredentials: allowedCreds,
          };

          let cred = {};
          try {
            //get sign in credentials from authenticator
            cred = await navigator.credentials.get({
              publicKey: signinOptions,
            });

          } catch (err) {
            console.log("ERR", err);
            throw {status:"Error", message: "Unfortunately, your authentication attempt with the security key has failed."};
          }
        
          let challengeresponse = "";
          //prepare credentials challenge response
          if (cred.response && cred.id) {
            const clientDataJSON = b64encode(cred.response.clientDataJSON);
            const authenticatorData = b64encode(
              cred.response.authenticatorData
            );
            const signature = b64encode(cred.response.signature);

            let enc = new TextEncoder();
            challengeresponse = b64encode(enc.encode(JSON.stringify({
              clientDataJSON:clientDataJSON,
              authenticatorData:authenticatorData,
              signature:signature,
              credId: cred.id
            })));
          }

          const responseData = await fetchOpenEnvelope(id, pin, challengeresponse);
          
          if (responseData.status === "Success") {

            if(!responseData.details || !responseData.details.type){
              throw {status: "Error", message: "Unfortunately, your authentication attempt with the security key has failed."};
            } else {
              setDetails(responseData.details);
            }

          } else if (
            responseData.status !== "Success" &&
            responseData.message
          ) {
            throw {status: "Error", message: responseData.message};
          } else {
            throw {status: "Error", message: "Whoops! We could not find your envelope."};
          }
          
        } else {
          setDetails(details);
        }
      } else {
        throw {status, message};
      }
    } catch (e) {
      console.log(e);
      /**
       * Clean PIN input
       */
      if (pin) {
        
        if(codeRef.current){
          if(codeRef.current.textInput && codeRef.current.textInput[0]) codeRef.current.textInput[0].focus();

          if(codeRef.current.state && codeRef.current.state.input){
            codeRef.current.state.input[0] = "";
            codeRef.current.state.input[1] = "";
            codeRef.current.state.input[2] = "";
            codeRef.current.state.input[3] = "";
            codeRef.current.state.input[4] = "";
            codeRef.current.state.input[5] = "";  
          }          
          
        }
        
      }

      if(e.status){
        setErrorAlert(e.message);
      }else{
        setErrorAlert("There was an error trying to open your envelope.");
      }
    }
  };

  function onPinChange(value:string){

      if(value.length == 6){
          setIsLoading(true);
          handleOpen(value);
      }
  }

  let typePIN = "number";

  if (pin == "pin") {
    typePIN = "password";
  }

  const readFile = async function () {
    let result = await new Promise((resolve) => {

        const reader = new FileReader()

        reader.onabort = (err) => {
          setIsLoading(false);
          console.log('file reading was aborted', err);
        }

        reader.onerror = (err) => {
          setIsLoading(false);
          console.log('file reading has failed', err);
        }

        reader.onload = () => {
          // Do whatever you want with the file contents
          console.log("Data set");
          resolve(reader.result);
        }

        reader.readAsDataURL(fileData);
    });

    return result;
  }

  return (
    <>
      {errorAlert && <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="There was an issue"
        onConfirm={() => setErrorAlert("")}
        confirmBtnBsStyle="danger"
        confirmBtnText="Close"
        btnSize=""
      >
        {errorAlert}
      </ReactBSAlert>}
      {isLoading && envelopeType && (
    
        <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            showCancel={false}
            showConfirm={false} title={""} onConfirm={function (response?: any) {
              throw new Error("Function not implemented.");
            } }        >
          <p className="display-3">{loadingText}</p>
        </ReactBSAlert>
        
      )}
      {envelopeSent && <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Envelope sent"
        onConfirm={() => window.location.reload()}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        Your envelope was sent successfully!!
      </ReactBSAlert>}
      <div className="content">
        <Formik
          
          initialValues={{}}
          validate={(values) => {
            console.log("Values validate: ", values);
            let errors = {};
            const error = "This field is required";
                                  
            if (
              envelopeType === "password" &&
              !values.password
            ) {
              errors.password = error;
              setFont((prevFont) => ({
                ...prevFont,
                noData: true,
              }));
            } else if (
              envelopeType === "password" &&
              values.password
            ) {
              let checked = zxcvbn(values.password);

              if (checked.score > -1 && checked.score <= 2) {
                setFont((prevFont) => ({
                  ...prevFont,
                  bgColor: "bg-danger",
                  color: "danger-txt",
                  text: "Weak",
                }));
              } else if (checked.score === 3) {
                setFont((prevFont) => ({
                  ...prevFont,
                  bgColor: "bg-warning",
                  color: "warning-txt",
                  text: "Ok",
                }));
              } else if (checked.score >= 4) {
                setFont((prevFont) => ({
                  ...prevFont,
                  bgColor: "bg-success",
                  color: "success-txt",
                  text: "Strong",
                }));
              }

              setFont((prevFont) => ({
                ...prevFont,
                description: `${checked.crack_times_display.online_no_throttling_10_per_second} to crack`,
                noData: false,
              }));
            } else if (
              envelopeType === "message" &&
              !values.message
            ) {
              errors.message = error;
            } else if (envelopeType === "credentials") {
              if (!values.credentialEmail) errors.credentialEmail = error;
              

              if (!values.credentialPassword) errors.credentialPassword = error;
            } else if (envelopeType === "custom") {
              if (!values.json) errors.json = error;
              else if (!isJsonString(values.json)) {
                errors.json = "Please, inter a valid JSON object";
              }
            } else if (envelopeType === "file" && !fileData.name) {
              setFileErrors(error);
              errors.file = error;
            }
            console.log("Errors validate: ", errors, fileData);
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {

            setSharepassBtnTxt("PLEASE WAIT...");

            let secretData; 

            switch (envelopeType) {
              case "password":
                secretData = values.password;
                break;
              case "message":
                secretData = values.message;
                break;
              case "credentials":
                secretData = {
                  email: values.credentialEmail,
                  password: values.credentialPassword,
                  hostname: values.credentialHostname,
                  f2a: values.credential2Fa,
                };
                break;
              case "custom":
                secretData = values.json;
                break;
              case "QR":
                secretData = qrData;
                break;
              case "file":
                setIsLoading(true);
                secretData = await readFile();
                
                //File is empty
                if(secretData == 'data:'){
                  setErrorAlert("Your file is empty. Please, try again with a different one.");
                                      
                  return;
                }

                setLoadingText("Encrypting file content...");
                break;
            }

            const { body }: any = await createEnvelopeBodyRequest(
              {
                data: secretData,
                type: envelopeType,
                sendingToken: sendingToken,
                secretId: id,
              },
              publicKey
            );

            /**
             * If type file lets create pre-signed URL and upload the file
             */
            if(envelopeType == 'file'){

              setLoadingText("Uploading file...");
              
              uploadDetails["details"]["fields"]["x-amz-meta-secretid"]    = id;
              uploadDetails["details"]["fields"]["x-amz-meta-hash"]        = uploadDetails.hash;
              uploadDetails["details"]["fields"]["x-amz-meta-filename"]    = fileData.name;
              

              const response = await fetchUploadFileToS3(uploadDetails["details"], body.secret);

              //File was uploaded correctly, update secret value with file key
              if(response.status == 204){
                body.secret = uploadDetails.details.fields.key;
                body.filename = fileData.name;
                body.hash = uploadDetails.hash;
              } else {

                console.log("status: ", response.status);
                setErrorAlert(response.message);
      
                return;
              }

              setIsLoading(false);
            }

            const { status, details, message, redirect } = await fetchSendEnvelope(
              body
            );

            if (status === "Success") {
              setSubmitting(false);
              setEnvelopeSent(true);
            } else {
              setErrorAlert(message);
            }
            
            //setSharepassBtnTxt("SHAREPASS IT!");
          }}
        >
          {({ isSubmitting }) => (
            <Form className="form-formik">
              {envelopeType ?            
                
                <Container >
                  <Row>
                    <Col className="ml-auto mr-auto" md="7">
                      <Card className="card-register form-envelope card-white no-mb">
                        <CardHeader className="card-header-sp">
                          <img
                            alt="..."
                            src={require("../../assets/img/sp.png").default}
                          />
                        </CardHeader>
                        <CardBody className="pBotttom0 ">
                          <div>{publicDescription}</div>
                          <div>Envelope will be sent to <b>{emailSender}</b></div>
                          {envelopeType === "password" && (
                            <InputPassword font={font} />
                          )}
                          {envelopeType === "message" && (
                            <InputMessage />
                          )}
                          {envelopeType === "credentials" && (
                            <InputCredential />
                          )}
                          {envelopeType === "custom" && <InputJson />}
                          {envelopeType === "QR" && (
                            <InputQr setQrData={setQrData} qrData={qrData} />
                          )}
                          {envelopeType === "file" && (
                            <InputFile  
                              setFileData={setFileData} 
                              errors={fileErrors} 
                              setErrors={setFileErrors} 
                              allowedFileSize={uploadDetails.allowedFileSize}
                            />
                          )}
                          <hr />
                          <Row className="center-sp">
                            <Button
                              className="btn-fill"
                              color="primary"
                              type="submit"
                              disabled={
                                isSubmitting ||
                                envelopeType === "QR" &&
                                  qrData === "No QR code scanned yet"
                              }
                            >
                              <FontAwesomeIcon
                                icon={faPaperPlane}
                                color="#fff"
                                className="right5"
                              />
                              {sharepassBtnTxt}
                            </Button>
                          </Row>
                          <Row className="mt-2">
                            <Col>
                              <div className={`progress-container ${progressBarClass}`}>
                                <Progress max="100" value={progressBar}>
                                  <span className="progress-value"></span>
                                </Progress>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  
                </Container>
                
              : 

                <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
                  <Container>
                    <Row>
                      <Col className="ml-auto mr-auto" md="7">
                        <Card className="card-register card-envelope card-white no-mb">
                          <CardHeader className="card-header-sp">
                            <img
                              alt="..."
                              src={require("../../assets/img/sp.png").default}
                            />
                          </CardHeader>
                          <CardBody className="pBotttom0">
                            <div className="text-center">
                              <h3>Open the envelope to send your data</h3>
                            </div>

                            <div className="form">
                              
                            {pin ? 
                              <>
                                <FormGroup className="center-sp">
                                  <div className="mt-3"></div>
                                  <div className="text-center">
                                      <div className="form-group d-i-block">
                                          <ReactCodeInput 
                                            inputMode="numeric"
                                            ref={codeRef} 
                                            value={''} 
                                            disabled={isLoading} 
                                            onChange={onPinChange} 
                                            type={typePIN} 
                                            fields={6}  
                                          />
                                      </div>
                                  </div>
                                  
                                </FormGroup>
                                {isLoading && 
                                <div className="form-group text-center">
                                  <label className="text-primary"><i className="fas fa-circle-notch fa-spin"></i> PLEASE WAIT</label>
                                </div>}
                              </>
                              :
                              <FormGroup className="center-sp">
                                <Button
                                  className={`btn-round ${
                                    isLoading ? "disabled" : ""
                                  }`}
                                  color="primary"
                                  //onClick={(e) => handleSubmit(e)}
                                  size="lg"
                                  type="button"
                                  onClick={handleOpen}
                                >
                                  { isLoading
                                    ? "Please wait..."
                                    : "OPEN"}
                                </Button>
                              </FormGroup>}

                              <div
                                className="form-group text-center privacy-policy-block"
                                style={{ color: "#4a4a4a" }}
                              >
                                {pin == "pin" || pin == "dpin" ? "By entering the PIN, you agree on our" : "By clicking 'OPEN', you agree on our" } <br />
                                <a
                                  className="mt-7px"
                                  href="https://sharepass.com/privacy-policy"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Privacy Policy for SharePass
                                </a>
                              </div>

                              <div className="form-group text-center">
                                <div className="mt-2 mb-2">
                                  <Button className="btn-simple" type="button" onClick={() => {setIsFlipped(!isFlipped)}}>
                                    <i className="fas fa-share-square"></i> Open in another device
                                  </Button>
                                </div>
                              </div>
                            </div>
                            <div id="ownerClickBack">
                              <small>Owner of the envelope? <a href={`${FrontendConfig.current_url}/admin/envelope/list?show_details=${id}`} target="_blank">Find it in your portal</a></small>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col className="ml-auto mr-auto" md="7">
                        <Card className="card-register card-envelope card-white no-mb">
                        
                          <CardBody className="pBotttom0">
                            <div className="text-center">
                              <div style={{ width: "100%" }}>
                                <QRCode
                                  value={window.location.href}
                                  size="250"
                                  logoImage="/assets/img/SP.png"
                                  logoWidth="40"
                                />
                              </div>
                              <div>Scan the QR code to open in another device.</div>
                              <div className="mt-2 mb-2">
                                  <Button className="btn-simple" type="button" onClick={() => {setIsFlipped(!isFlipped)}}>
                                    <i className="fas fa-share-square"></i> Back
                                  </Button>
                                </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </ReactCardFlip>
              }
            </Form> 
            )}
        </Formik>
      </div>
    </>
  );
};
